<template>
  <div class="">
    <Iconfb v-if="type == 'facebook'" class="main-icon icon-fb" />
    <Iconreddit v-if="type == 'reddit'" class="main-icon icon-reddit" />
    <Iconli v-if="type == 'linkedin'" class="main-icon icon-li" />
    <Iconwa v-if="type == 'whatsapp'" class="main-icon icon-wa" />
    <Iconpin v-if="type == 'pinterest'" class="main-icon icon-pin" />
    <Iconinsta v-if="type == 'instagram'" class="main-icon icon-insta" />
    <Iconmedium v-if="type == 'medium'" class="main-icon icon-medium" />
    <Iconyt v-if="type == 'youtube'" class="main-icon icon-yt" />
    <Icontwi v-if="type == 'twitter'" class="main-icon icon-twi" />
    <Icongit v-if="type == 'github'" class="main-icon icon-git" />
    <Iconstack v-if="type == 'stackoverflow'" class="main-icon icon-stack" />
    <Iconemail v-if="type == 'email'" class="main-icon icon-email" />
  </div>
</template>

<script>
import Iconfb from "../../assets/svg/icon-fb.vue";
import Iconreddit from "../../assets/svg/icon-reddit.vue";
import Iconli from "../../assets/svg/icon-li.vue";
import Iconwa from "../../assets/svg/icon-wa.vue";
import Iconpin from "../../assets/svg/icon-pin.vue";
import Iconinsta from "../../assets/svg/icon-insta.vue";
import Iconmedium from "../../assets/svg/icon-medium.vue";
import Iconyt from "../../assets/svg/icon-yt.vue";
import Icontwi from "../../assets/svg/icon-twi.vue";
import Iconstack from "../../assets/svg/icon-stack.vue";
import Icongit from "../../assets/svg/icon-git.vue";
import Iconemail from "../../assets/svg/icon-email.vue";
export default {
  name: "SocialIcon",
  components: {
    Iconfb,
    Iconreddit,
    Iconli,
    Iconwa,
    Iconpin,
    Iconinsta,
    Iconmedium,
    Iconyt,
    Icontwi,
    Iconstack,
    Icongit,
    Iconemail,
  },
  props: {
    type: {
      type: String,
      default: "reddit",
    },
  },
};
</script>

<style lang="scss" scoped>
.main-icon {
  background-color: $grey-400;
  border-radius: $border-radius;
}
.icon-reddit {
  fill: #ff4500;
  background-color: $white;
}
.icon-fb {
  fill: #1877f2;
  background-color: $white;
}
.icon-li {
  fill: #0a66c2;
  background-color: $white;
}
.icon-wa {
  fill: #25d366;
  background-color: $white;
}
.icon-pin {
  fill: #bd081c;
  background-color: $white;
}
.icon-insta {
  fill: #c32aa3;
  background-color: $white;
}
.icon-medium {
  fill: #02b875;
  background-color: $white;
}
.icon-yt {
  fill: #ff0000;
  background-color: $white;
}
.icon-twi {
  fill: #1da1f2;
  background-color: $white;
}
.icon-git {
  fill: #171515;
  background-color: $white;
}
.icon-stack {
  fill: #ef8236;
  background-color: $white;
}
.icon-email {
  background-color: $grey-500;
}
@media screen and (max-width: $switch-width) {
}
</style>
